<template>
  <div class="page">
    <Navbar title="任务分享" />
    <van-search
      v-model="condition.roName"
      show-action
      placeholder="请输入职位关键词"
      @search="onSearch"
    >
      <template #action>
        <div @click="siftCondition">搜索</div>
      </template>
    </van-search>
    <div class="store">
      <van-row class="name">
        <van-col span="24"
          >服务商：{{ store.name }}
          <van-tag type="warning" v-if="store.type === 'SEF'">自营</van-tag
          ><van-tag type="warning" v-if="store.type === 'MCO'"
            >微店</van-tag
          ></van-col
        >
      </van-row>
      <van-row class="link">
        <van-col span="24">
          联系人：{{ store.linkPerson }}
          <van-tag :color="COLOR" class="tag" @click="telphone()"
            >拨打电话</van-tag
          >
          <van-tag :color="COLOR" class="tag" @click="showWechat()">
            添加微信</van-tag
          ></van-col
        >
      </van-row>
    </div>
    <div class="tasks" v-show="taskShow">
      <van-tabs :active="active" :color="COLOR" border="true">
        <van-tab title="工作职位" name="recmd">
          <Sift :callback="siftCondition" />
          <van-checkbox-group v-model="rctCheck" :max="5">
            <van-list
              error-text="请求失败，点击重新加载"
              @load="changePage('NEXT')"
              :immediate-check="false"
            >
              <van-cell v-for="task in tasks" :key="task.code">
                <template #default>
                  <van-row class="task">
                    <van-col span="24" class="content">
                      <van-row>
                        <van-col span="2" class="checkbox">
                          <van-checkbox
                            :name="task.code"
                            shape="square"
                            icon-size="15px"
                            :checked-color="COLOR"
                          ></van-checkbox>
                        </van-col>
                        <van-col span="22" class="title"
                          >【职位：{{ task.name }}】【薪资：
                          <span v-if="task.salaryMin > 1000"
                            >{{ task.salaryMin / 1000 }}K-{{
                              task.salaryMax / 1000
                            }}K</span
                          ><span v-if="task.salaryMin < 1000"
                            >{{ task.salaryMin }}-{{ task.salaryMax }}</span
                          >/<span v-if="task.salaryType === 'YER'">年</span>
                          <span v-if="task.salaryType === 'MOT'">月</span>
                          <span v-if="task.salaryType === 'DAY'">日</span
                          >】【福利:<span
                            v-for="item in task.label"
                            :key="item.name"
                            class="item"
                            >{{ item.name }} </span
                          >】</van-col
                        >
                      </van-row>
                    </van-col>
                  </van-row>
                </template>
              </van-cell>
            </van-list>
          </van-checkbox-group></van-tab
        >
        <van-tab title="学校招生" name="all"></van-tab>
      </van-tabs>
    </div>
    <div class="operate">
      <van-row class="button">
        <van-col span="12">
          <van-button
            class="btn"
            :color="COLOR"
            size="small"
            @click="createMessage()"
            >生成分享内容</van-button
          >
        </van-col>
      </van-row>
    </div>
    <van-popup :show="loadingShow" style="background-color: transparent"
      ><van-loading size="24px" :color="COLOR" vertical>加载中...</van-loading>
    </van-popup>
    <van-overlay :show="wechatShow">
      <div class="wechat">
        <div>
          <img :src="STATIC_URL + '/' + store.linkWechat" class="img" />
        </div>
        <div class="text">长按二维码添加微信</div>
        <div class="button">
          <van-button
            type="primary"
            size="mini"
            block
            @click="wechatShow = false"
            >关闭</van-button
          >
        </div>
      </div>
    </van-overlay>
    <Share ref="share" />
    <!-- <Tabbar mode="enroll" active="home" /> -->
  </div>
</template>
<script>
import {
  Search, Swipe, SwipeItem, Tabs, Tab, Tag, Overlay, CheckboxGroup, Checkbox, Field, List, Cell
} from 'vant'
import Navbar from '../../common/Navbar.vue'
// import Tabbar from '../../common/Tabbar.vue'
import Share from '../../common/Share.vue'
import Sift from '../../recruit/common/Sift.vue'
export default {
  components: {
    Navbar: Navbar,
    // Tabbar: Tabbar,
    Share: Share,
    Sift: Sift,
    [Search.name]: Search,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Tag.name]: Tag,
    [Overlay.name]: Overlay,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Field.name]: Field,
    [List.name]: List,
    [Cell.name]: Cell
  },
  data () {
    return {
      page: { current: 1, size: 20, last: 1 },
      operatorCode: '',
      condition: { roName: '', roInstIndustry: '', roInstNature: '', roInstType: '', roInstScale: '', roType: '' },
      operator: { name: '', address: '', linkPerson: '', linkPhone: '', linkWechat: '', type: '' },
      loadingShow: false,
      wechatShow: false,
      code: '',
      active: 'recmd',
      tasks: [],
      workers: [],
      loginState: 'N',
      studentState: 'N',
      rctCheck: [],
      messageShow: false,
      taskShow: true
    }
  },
  mounted: function () {
    var query = this.$route.query
    var operator = query.operator
    var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
    if (operator !== undefined && operator !== '' && operator !== null) {
      window.sessionStorage.setItem(this.SESSION_OPERATOR, operator)
    }
    if (query.code !== undefined && token === null) {
      this.code = query.code
      this.login()
    } else if (token === null) {
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa8145a74e5f2b39a&redirect_uri=https%3A%2F%2Fmoc.utopanet.com%2Fshare%2Fhome%3Fmerchant%3D' + operator + '&response_type=code&scope=snsapi_base&state=123#wechat_redirect'
      this.loginState = 'Y'
    } else {
      this.loginState = 'Y'
      this.retrieveTaskList()
      this.retrieveOperator()
    }
    this.initShare()
  },
  methods: {
    async login () {
      var pd = { code: this.code, source: 'WPP' }
      var { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/loginBind', this.$qs.stringify(pd))
      if (res.status === '200') {
        window.sessionStorage.setItem(this.SESSION_TOKEN, res.data.token)
        window.sessionStorage.setItem(this.SESSION_USER, res.data.userCode)
        this.loginState = 'Y'
        this.retrieveTaskList()
        this.retrieveOperator()
        window.sessionStorage.setItem(this.SESSION_SERIAL, res.data.openid)
        this.$router.go(0)
      } else if (res.code === '4002') {
        window.sessionStorage.setItem(this.SESSION_SERIAL, res.data.openid)
        this.$router.push({ path: '/bind' })
      }
    },
    initShare () {
      var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var title = '微职客分享助手'
      var desc = '快速帮您生成分享内容，为营销推广赋能'
      var link = 'https://moc.utopanet.com/share?f=1'
      if (operator !== undefined && operator !== null && operator !== '') {
        link = link + '&operator=' + operator
      }
      var logo = 'https://moc.utopanet.com/static/logo.png'
      this.$refs.share.init(title, desc, link, logo)
    },
    async changePage (type) {
      if (this.page.current === this.page.last) {
        this.$dialog.alert({
          title: '提示',
          message: '无更多职位记录'
        }).then(() => {
          // on close
        })
      } else {
        this.page.current = this.page.current + 1
        this.retrieveTaskList()
      }
    },
    siftCondition (condition) {
      this.tasks = []
      this.condition = { ...this.condition, ...condition }
      this.retrieveTaskList()
    },
    async retrieveOperator () {
      var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var pd = { operatorCode: operator }
      const { data: res } = await this.$http.post(this.UMS_URL + '/operate/operator/retrieveOperator', this.$qs.stringify(pd))
      if (res.status === '200') {
        console.log(res.data)
        this.store = res.data
      }
    },
    async retrieveTaskList () {
      this.loadingShow = true
      this.condition.current = this.page.current
      this.condition.size = this.page.size
      var { data: res } = await this.$http.post(this.BMS_URL + '/recruit/task/retrieveSiftTaskList', this.$qs.stringify(this.condition))
      if (res.status === '200') {
        this.tasks = this.tasks.concat(res.data)
        this.page.last = res.page.last
      }
      this.loadingShow = false
    },
    telphone () {
      var phone = this.store.linkPhone
      window.location.href = 'tel:' + phone
    },
    showWechat () {
      this.wechatShow = true
    },
    createMessage () {
      this.$router.push({ path: '/mde/share/detail', query: { type: 'RCT', codes: this.rctCheck.join(',') } })
    }
  }
}
</script>
<style lang="less" scoped>
.store {
  width: 96%;
  margin: 10px 2%;
  padding: 5px 0px;
  line-height: 25px;
  box-shadow: 0 0 9px 3px #eee;
  border-radius: 10px;
  .name {
    font-size: 13px;
    font-weight: 600;
  }
  .tag {
    margin-right: 3px;
  }
}
.tasks {
  padding: 5px 5px;
  margin-bottom: 50px;
}
.tasks .title {
  text-align: left;
  font-size: 13px;
  padding: 5px 5px;
}

.tasks .task {
  padding: 0px 0px;
}
.tasks .task .title {
  text-align: left;
  padding: 5px 5px;
  overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
  // -webkit-line-clamp: 1;
}
.checkbox {
  margin: auto;
}
.item {
  padding: 2px 5px;
  margin-right: 3px;
}
.wechat {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .img {
    width: 120px;
    height: 120px;
  }
  .text {
    width: 120px;
    height: 30px;
    line-height: 30px;
    background-color: #fff;
  }
  .button {
    width: 120px;
  }
}
.operate {
  padding: 5px 0px;
  background-color: #fff;
  height: 50px;
  position: fixed;
  width: 100%;
  display: flex;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  bottom: 0px;
  border-radius: 10px;
  z-index: 999;
  box-shadow: 0px 0px 5px #bbb;
}
.operate .button {
  display: flex;
  flex-flow: row;
  height: 50px;
  line-height: 50px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.operate .button .btn {
  width: 90%;
  height: 30px;
  margin: 0px 1px;
  line-height: 30px;
  border: 1px solid #eee;
}
</style>
